import { useEffect, useState, useContext, useRef } from 'react';

import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  TextField,
  Button,
  FormGroup,
  FormControlLabel,
  Checkbox,
  CircularProgress,
  Box
} from '@material-ui/core';
import appContext from "../../context/appContext";

import { getToken, getTokenAppCheck } from '../../helpers/firebase';
import ReactQuill from "react-quill"
import 'react-quill/dist/quill.snow.css'

var BackgroundStyle = ReactQuill.Quill.import('attributors/style/background');
var ColorStyle = ReactQuill.Quill.import('attributors/style/color');
var SizeStyle = ReactQuill.Quill.import('attributors/style/size');
var Alignment = ReactQuill.Quill.import('attributors/style/align');
ReactQuill.Quill.register(BackgroundStyle, true);
ReactQuill.Quill.register(ColorStyle, true);
ReactQuill.Quill.register(SizeStyle, true);
ReactQuill.Quill.register(Alignment, true);


const AppMessagesResults = () => {
  const context = useContext(appContext);
  const [currentAlert, setCurrentAlert] = useState('');
  const [blockapp, setBlockApp] = useState(false)
  const [active, setActive] = useState(false)


  const [content, setContent] = useState('')
  const [title, setTitle] = useState('')
  const [display, setDisplay] = useState('none')


  const [androidVersion, setAndroidVersion] = useState('')
  const [iosVersion, setIosVersion] = useState('')
  const [webVersion, setWebVersion] = useState('')



  const [message, setMessage] = useState('')
  const modules = {
    toolbar: [
      [{ font: [] }],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline", "strike"],
      [{ color: [] }, { background: [] }],
      [{ script: "sub" }, { script: "super" }],
      ["blockquote", "code-block"],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ indent: "-1" }, { indent: "+1" }, { align: [] }],
      ["link"],
      ["clean"],
    ],
  };
  const config = {
    readonly: false // all options from https://xdsoft.net/jodit/doc/
  }
  let setPreloader = (value) => {
    context.dispatch({ type: "SET_LOADING_INNER", payload: value })
  }
  useEffect(async () => {
    getMessages();
  }, [context.user]);



  const getMessages = async () => {
    let token = await getToken();
    let appCheckToken = await getTokenAppCheck();

    console.log(token);
    console.log(window.location.hostname === "localhost" ? `${process.env.REACT_APP_URL_DEBUG}/dev` : `${process.env.REACT_APP_URL}/api`);
    const response = await fetch(`${window.location.hostname === "localhost" ? `${process.env.REACT_APP_URL_DEBUG}/dev` : `${process.env.REACT_APP_URL}/api`}/getAllMessages`, {
      method: 'POST',
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token,
        'X-Firebase-AppCheck': appCheckToken

      },
      redirect: 'follow', // manual, *follow, error
      referrerPolicy: 'no-referrer',

    });
    console.log(response);
    const resJson = await response.json();
    console.log(resJson.data);
    context.dispatch({ type: "GET_ALL_MESSAGES", payload: resJson.data })
  }

  let handleChange = (e) => {
    console.log(e.target);
    setCurrentAlert(e.target.value);
    setDisplay(true);
    let currentMessage = context.state.messages.find((element) => element.id == e.target.value);
    setContent(currentMessage.content);
    setBlockApp(currentMessage.blockapp);
    setIosVersion(currentMessage.iosVersion);
    setWebVersion(currentMessage.webVersion);
    setAndroidVersion(currentMessage.androidVersion);
    setActive(currentMessage.active);


  }

  let updateMessage = async () => {

    setPreloader(true);
    let token = await getToken();
    let appCheckToken = await getTokenAppCheck();

    console.log(window.location.hostname === "localhost" ? `${process.env.REACT_APP_URL_DEBUG}/dev` : `${process.env.REACT_APP_URL}/api`);
    let response = await fetch(`${window.location.hostname === "localhost" ? `${process.env.REACT_APP_URL_DEBUG}/dev` : `${process.env.REACT_APP_URL}/api`}/updateMessageApp`, {
      method: 'POST',
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token,
        'X-Firebase-AppCheck': appCheckToken
      },
      redirect: 'follow', // manual, *follow, error
      referrerPolicy: 'no-referrer',
      body: JSON.stringify({
        id: currentAlert,
        title: title,
        content: content,
        blockapp: blockapp,
        iosVersion: iosVersion,
        androidVersion: androidVersion,
        webVersion: webVersion,
        active: active,
      })
    });
    let resUpdate = await response.json();
    console.log();
    setPreloader(false);
    setMessage(resUpdate.msg);
    context.dispatch({ type: "GET_ALL_MESSAGES", payload: resUpdate.data })

  }


  return (
    <Grid container spacing={2}>

      <Grid item xs={4} >
        <FormControl fullWidth>
          <InputLabel id="app-messages">App messages</InputLabel>
          <Select
            labelId="app-messages"
            value={currentAlert}
            label="Alert"
            onChange={handleChange}
          >
            {
              context.state.messages.map((message) => <MenuItem value={message.id}>{message.description}</MenuItem>)
            }
          </Select>
        </FormControl>
      </Grid>


      <Grid container sx={{ display: display }}>
        <FormGroup>
          <FormControlLabel control={<Checkbox checked={blockapp} onChange={() => setBlockApp(!blockapp)} />} label="Block users from using app" />
          <FormControlLabel control={<Checkbox checked={active} onChange={() => setActive(!active)} />} label="Active?" />

        </FormGroup>
        <Grid container sx={{ display: display }}>
          <TextField id="outlined-basic" label="IOS version" variant="standard" value={iosVersion} onChange={(state) => setIosVersion(state.target.value)} />
          <TextField id="outlined-basic" label="Android version" variant="standard" value={androidVersion} onChange={(state) => setAndroidVersion(state.target.value)} />
          <TextField id="outlined-basic" label="Web version" variant="standard" value={webVersion} onChange={(state) => setWebVersion(state.target.value)} />


        </Grid>
        <ReactQuill
          theme='snow'
          value={content}
          onChange={setContent}
          style={{ minHeight: '300px', width: '100%' }}
          modules={modules}

        />



        <Grid container xs={{ size: 12, display: 'block' }} mt={10}>

          <Grid container justifyContent="center">
            <Button variant="outlined" onClick={updateMessage}  >Save</Button>
          </Grid>
          {message != "" && message}
        </Grid>
      </Grid>
    </Grid>
  );
};


export default AppMessagesResults;
