import { useEffect, useState, useContext, useRef } from 'react';

import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  TextField,
  Button,
  CircularProgress,
  TextareaAutosize,
  Box
} from '@material-ui/core';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';

import appContext from "../../context/appContext";

import { getToken, getTokenAppCheck } from '../../helpers/firebase';

import SunEditor from 'suneditor-react';
import SunEditorCore from "suneditor/src/lib/core";
import 'suneditor/dist/css/suneditor.min.css'
import { concat } from 'lodash';


/*
import ReactQuill from "react-quill"
import htmlEditButton from "quill-html-edit-button";
import 'react-quill/dist/quill.snow.css'

var BackgroundStyle = ReactQuill.Quill.import('attributors/style/background');
var ColorStyle = ReactQuill.Quill.import('attributors/style/color');
var SizeStyle = ReactQuill.Quill.import('attributors/style/size');
var Alignment = ReactQuill.Quill.import('attributors/style/align');
ReactQuill.Quill.register(BackgroundStyle, true);
ReactQuill.Quill.register(ColorStyle, true);
ReactQuill.Quill.register(SizeStyle, true);
ReactQuill.Quill.register(Alignment, true);
ReactQuill.Quill.register("modules/htmlEditButton", htmlEditButton);

*/


const AgreementTemplateResults = () => {
  const context = useContext(appContext);
  const [currentAlert, setCurrentAlert] = useState('');
  const [content, setContent] = useState('')
  const jsonText = useRef(null);
  const outputHtml = useRef(null);
  let fields = [];
  const [editorStatus, setEditorStatus] = useState(true);


  /*
    const modules = {
      'htmlEditButton': {},
      toolbar: [
        [{ table: [] }],
        [{ font: [] }],
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        ["bold", "italic", "underline", "strike"],
        [{ color: [] }, { background: [] }],
        [{ script: "sub" }, { script: "super" }],
        ["blockquote", "code-block"],
        [{ list: "ordered" }, { list: "bullet" }],
        [{ indent: "-1" }, { indent: "+1" }, { align: [] }],
        ["link"],
        ["clean"],
      ],
    };
  */

  let setPreloader = (value) => {
    context.dispatch({ type: "SET_LOADING_INNER", payload: value })
  }

  useEffect(async () => {
    getAgreementFields();
  }, [context.user]);



  let saveAgreementContent = async () => {

    setPreloader(true);
    let token = await getToken();
    let appCheckToken = await getTokenAppCheck();

    console.log(window.location.hostname === "localhost" ? `${process.env.REACT_APP_URL_DEBUG}/dev` : `${process.env.REACT_APP_URL}/api`);
    let response = await fetch(`${window.location.hostname === "localhost" ? `${process.env.REACT_APP_URL_DEBUG}/dev` : `${process.env.REACT_APP_URL}/api`}/saveAgreementContent`, {
      method: 'POST',
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token,
        'X-Firebase-AppCheck': appCheckToken

      },
      redirect: 'follow', // manual, *follow, error
      referrerPolicy: 'no-referrer',
      body: JSON.stringify({
        html: context.state.agreementContent
      })
    });
    let resUpdate = await response.json();
    console.log('resUpdate', resUpdate);
    setPreloader(false);

    context.dispatch({ type: "GET_ALL_AGREEMENT_CONTENT", payload: resUpdate.data.content })

  }
  const getAgreementFields = async () => {
    setPreloader(true);

    let token = await getToken();
    let appCheckToken = await getTokenAppCheck();

    const response = await fetch(`${window.location.hostname === "localhost" ? `${process.env.REACT_APP_URL_DEBUG}/dev` : `${process.env.REACT_APP_URL}/api`}/getAgreementFieldsAndContent`, {
      method: 'POST',
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token,
        'X-Firebase-AppCheck': appCheckToken
      },
      redirect: 'follow', // manual, *follow, error
      referrerPolicy: 'no-referrer',

    });
    console.log(response);
    const resJson = await response.json();
    console.log(resJson.fields);
    console.log(resJson.content)
    context.dispatch({ type: "GET_ALL_AGREEMENT_FIELDS", payload: resJson.fields })
    context.dispatch({ type: "GET_ALL_AGREEMENT_CONTENT", payload: resJson.content })
    fields = resJson.fields;
    setPreloader(false);
    setEditorStatus(false)

  }

  let buildAgreementHtml = async () => {
    console.log(jsonText.current.value);
    setPreloader(true);
    let token = await getToken();
    let appCheckToken = await getTokenAppCheck();

    console.log(window.location.hostname === "localhost" ? `${process.env.REACT_APP_URL_DEBUG}/dev` : `${process.env.REACT_APP_URL}/api`);
    let response = await fetch(`${window.location.hostname === "localhost" ? `${process.env.REACT_APP_URL_DEBUG}/dev` : `${process.env.REACT_APP_URL}/api`}/buildAgreementHtml`, {
      method: 'POST',
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token,
        'X-Firebase-AppCheck': appCheckToken


      },
      redirect: 'follow', // manual, *follow, error
      referrerPolicy: 'no-referrer',
      body: JSON.stringify({
        info: jsonText.current.value,
        html: context.state.agreementContent
      })
    });
    let resUpdate = await response.json();
    console.log('resUpdate', resUpdate);
    setPreloader(false);



    window.open(resUpdate.redirectUrl, "_blank");
  }
  const mergeTag = {
    // @Required
    // plugin name
    name: 'Tags',

    // @Required
    // data display
    display: 'submenu',
    innerHTML: 'Tags',

    // @Required
    // add function - It is called only once when the plugin is first run.
    // This function generates HTML to append and register the event.
    // arguments - (core : core object, targetElement : clicked button element)
    add: function (core, targetElement) {
      console.log(fields)
      // Generate submenu HTML
      // Always bind "core" when calling a plugin function
      let listDiv = this.setSubmenu.call(core);

      // You must bind "core" object when registering an event.
      /** add event listeners */
      var self = this;
      listDiv.querySelectorAll('.se-btn-list').forEach(function (btn) {
        btn.addEventListener('click', self.onClick.bind(core));
      });

      // @Required
      // You must add the "submenu" element using the "core.initMenuTarget" method.
      /** append target button menu */
      core.initMenuTarget(this.name, targetElement, listDiv);
    },

    setSubmenu: function () {

      const listDiv = this.util.createElement('DIV');
      // @Required
      // A "se-submenu" class is required for the top level element.
      listDiv.className = 'se-submenu se-list-layer';

      let html = '<div class="se-list-inner se-list-font-size"><ul class="se-list-basic">';
      for (let index = 0; index < fields.length; index++) {
        const element = fields[index];
        html += `<li><button type="button" class="se-btn-list" value="${element.name}">${element.name}</button></li>`;

      }


      html += '</ul></div>';
      listDiv.innerHTML = html;
      return listDiv;
    },
    onClick: function (e) {
      const value = e.target.value;
      const node = this.util.createElement('span');
      this.util.addClass(node, 'se-custom-tag');
      node.textContent = value;

      this.insertNode(node);
      const zeroWidthSpace = this.util.createTextNode(this.util.zeroWidthSpace);
      node.parentNode.insertBefore(zeroWidthSpace, node.nextSibling);

      this.submenuOff();
    }
  };


  return (

    <Grid container spacing={2}>


      <Grid container >
        {/*
        
       <ReactQuill
          theme='snow'
          value={context.state.agreementContent}
          onChange={(e) => {
            context.dispatch({ type: "GET_ALL_AGREEMENT_CONTENT", payload: e })
          }}
          style={{ minHeight: '300px', width: '100%' }}
          modules={modules}

        />
  */}
        <SunEditor
          disable={editorStatus}
          setContents={context.state.agreementContent}
          onChange={(e) => {
            context.dispatch({ type: "GET_ALL_AGREEMENT_CONTENT", payload: e })
          }}
          setOptions={{
            /*
plugins: [
  mergeTag,
  'font',
  'size',
  'table'
],
*/
            buttonList: [
              ["undo", "redo"],
              ["font", "fontSize"],
              // ['paragraphStyle', 'blockquote'],
              [
                "bold",
                "underline",
                "italic",
                "strike",
                "subscript",
                "superscript"
              ],
              ["fontColor", "hiliteColor"],
              ["align", "list", "lineHeight"],
              ["outdent", "indent"],

              ["table", "horizontalRule", "link", "image", "video"],
              // ['math'] //You must add the 'katex' library at options to use the 'math' plugin.
              // ['imageGallery'], // You must add the "imageGalleryUrl".
              // ["fullScreen", "showBlocks", "codeView"],
              ["preview", "print"],
              ["removeFormat", "codeView"]// "Tags"

              // ['save', 'template'],
              // '/', Line break
            ],
            // Or Array of button list, eg. [['font', 'align'], ['image']]
            defaultTag: "",
            minHeight: "300px",
            showPathLabel: false,
          }}
        />
        <Grid container xs={{ size: 12, display: 'block' }} mt={10}>

          <Grid container justifyContent="center">
            <Button variant="outlined" onClick={saveAgreementContent}  >Save</Button>
          </Grid>
        </Grid>
        <Grid xs={12} mt={2}>
          <TextareaAutosize
            style={{ width: "100%", height: "500px" }}
            ref={jsonText}
          />
        </Grid>
        <Grid xs={12} mt={2} align="center"
          justify="center"
          direction="column">
          <Button variant="outlined" onClick={() => {
            console.log(jsonText.value)
            buildAgreementHtml();

          }}  >Compile</Button>

        </Grid>


      </Grid>
    </Grid>

  );
};


export default AgreementTemplateResults;


