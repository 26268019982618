import React, { useContext, useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import appContext from "../../context/appContext";
import LinearProgress from '@material-ui/core/LinearProgress';
import Box from '@material-ui/core/Box';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/core/Alert';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { getToken, getTokenAppCheck } from '../../helpers/firebase';

export default function CreateLinkCsvDialog() {
    const [open, setOpen] = useState(false);
    const [showLoader, setShowLoader] = useState('none');
    const [snackBarMessageShow, setSnackBarMessageShow] = useState(false);
    const [toastMessage, setToastMessage] = useState('');
    const [file, setFile] = useState({});

    const [errors, setErrors] = useState({ file: false });
    const context = useContext(appContext);
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };





    const addUser = async () => {
        setShowLoader('block');
        const formData = new FormData();
        let hasError = false;

        if (file.name == undefined) {
            setErrors({ file: true });
            hasError = true;
        } else {
            setErrors({ file: false });
        }

        if (hasError) {
            setShowLoader('none');
            return;
        }

        formData.append("csv", file);
        let token = await getToken();
        let appCheckToken = await getTokenAppCheck();

        const response = await fetch(`${window.location.hostname === "localhost" ? `${process.env.REACT_APP_URL_DEBUG}/dev` : `${process.env.REACT_APP_URL}/api`}/addDeepLinksCsv`, {
            method: 'POST',
            body: formData,
            headers: {
                'Authorization': token,
                'X-Firebase-AppCheck': appCheckToken

            }
        });
        const resJson = await response.json();
        console.log(resJson);

        context.dispatch({ type: "GET_USERS_LINKS", payload: resJson.data })
        setOpen(false);
        setSnackBarMessageShow(true);
        setToastMessage(resJson.msg)
        setShowLoader('none');
    }

    const handleFileUpload = (e) => {
        console.log(e.target.files[0]);
        setFile(e.target.files[0]);
    }

    const handleToastClose = (event, reason) => {

        setSnackBarMessageShow(false);
    };



    return (
        <span>

            <Snackbar open={snackBarMessageShow} onClose={handleToastClose} autoHideDuration={4000}>
                <Alert severity="info">
                    {toastMessage}
                </Alert>
            </Snackbar>
            <Button variant="outlined" color="primary" onClick={handleClickOpen}>
                Add users from CSV
            </Button>
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle onClose={handleClose} id="form-dialog-title">ADD USERS <IconButton aria-label="close" onClick={handleClose}>
                    <CloseIcon />
                </IconButton></DialogTitle>
                <DialogContent>

                    <Button
                        variant="contained"
                        component="label"

                    >
                        Upload CSV File
                        <input
                            type="file"
                            name="csv"
                            onChange={handleFileUpload}
                            hidden
                        />
                    </Button>

                    <Box component="span" display={showLoader}>
                        <LinearProgress />
                    </Box>

                </DialogContent>
                <DialogActions>

                    <Button onClick={addUser} color="primary">
                        Upload
                    </Button>

                </DialogActions>
            </Dialog>
        </span >
    );
}
