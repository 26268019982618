import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/app-check'; // Import the Firebase App Check module
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";



const firebaseConfig = {
    apiKey: "AIzaSyAdJ2oFrDa_f0HkX6KvMeMj-MJz1jrIN_U",
    authDomain: "lecfl-59ccf.firebaseapp.com",
    databaseURL: "https://lecfl-59ccf-default-rtdb.firebaseio.com",
    projectId: "lecfl-59ccf",
    storageBucket: "lecfl-59ccf.appspot.com",
    messagingSenderId: "350994751838",
    appId: "1:350994751838:web:fa294d3b35ba50dbb56fcf",
    appCheckToken: '6Ld-cfQmAAAAAKDDxHovM57xh2bbdUWPgyRGHX-T',
};
const app = firebase.initializeApp(firebaseConfig);


/*
const appCheck = initializeAppCheck(app, {
    provider: new ReCaptchaV3Provider('6LfFuR0bAAAAAI8eq_PgBTjTp0xzQ1vawc3UKOYw'),

    // Optional argument. If true, the SDK automatically refreshes App Check
    // tokens as needed.
    isTokenAutoRefreshEnabled: true
});
*/

firebase.appCheck().activate('ignored', false);


const provider = new firebase.auth.GoogleAuthProvider();
provider.setCustomParameters({ prompt: 'select_account' });

export const signInWithEmailAndPassword = async (email, password) => {
    try {
        await auth.signInWithEmailAndPassword(email, password);

    } catch (e) {
        alert(e)
    }

}
export const signOut = () => auth.signOut();

export const isAdmin = async () => {
    let idToken = await firebase.auth().currentUser.getIdTokenResult();

    if (idToken.claims.admin) {
        return true;
    } else {
        return false;
    }
}
export const getToken = async () => {
    let currentUser = await firebase.auth().currentUser;
    console.log(currentUser);
    if (currentUser != null) {
        let idToken = await currentUser.getIdToken();
        console.log(idToken);
        return idToken;
    } else {
        return null;
    }

}

export const getTokenAppCheck = async () => {
    console.log(firebase.appCheck())
    let token = await firebase.appCheck().getToken();
    console.log(token);
    if (token != null) {
        return token.token;
    } else {
        return null;
    }

}



export const getCurrentUserUid = async () => {
    let currentUser = await firebase.auth().currentUser;
    console.log(currentUser);
    if (currentUser != null) {

        return currentUser.uid;
    } else {
        return null;
    }

}

export const auth = firebase.auth();
export default firebase;